import { default as aboutSTyhmcoC9gMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/about.vue?macro=true";
import { default as billingFSXmdKzuUGMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/account/billing.vue?macro=true";
import { default as indexmJKQzH1GplMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/account/index.vue?macro=true";
import { default as profilebWVjLByjxvMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/account/profile.vue?macro=true";
import { default as accountacKeTLyi0XMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/account.vue?macro=true";
import { default as authorizebC0zWCw3AOMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/authorize.vue?macro=true";
import { default as chatDu1eOtaRXSMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/chat.vue?macro=true";
import { default as contactujEufyMBCwMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/contact.vue?macro=true";
import { default as index6JVFuo1GsQMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testfBozpUHxUBMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/internet-speed-test.vue?macro=true";
import { default as logine2fIx7vbE7Meta } from "/codebuild/output/src1670677190/src/comapss-web/pages/login.vue?macro=true";
import { default as logoutZmx9vn8KrPMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/logout.vue?macro=true";
import { default as our_45planss2WAaFjQBfMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/our-plans.vue?macro=true";
import { default as plans0iG8FIexSuMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/plans.vue?macro=true";
import { default as privacyjOLxfmz8IdMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/privacy.vue?macro=true";
import { default as promotionsXqgAhBumHFMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/promotions.vue?macro=true";
import { default as _1BzEFqqG21EMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/1.vue?macro=true";
import { default as _2V0LzB7wxTEMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/2.vue?macro=true";
import { default as _3lpHF0scz9CMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/3.vue?macro=true";
import { default as _43rVTxasymmMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/4.vue?macro=true";
import { default as _5rJKLqsZdskMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/5.vue?macro=true";
import { default as _6loJAAl0MNgMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/6.vue?macro=true";
import { default as indexFYbJe3UNfNMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/index.vue?macro=true";
import { default as successv3whoMdfuJMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register/success.vue?macro=true";
import { default as registermfHdMOhxJ0Meta } from "/codebuild/output/src1670677190/src/comapss-web/pages/register.vue?macro=true";
import { default as terms6DdCqxBbrDMeta } from "/codebuild/output/src1670677190/src/comapss-web/pages/terms.vue?macro=true";
export default [
  {
    name: aboutSTyhmcoC9gMeta?.name ?? "about",
    path: aboutSTyhmcoC9gMeta?.path ?? "/about",
    meta: aboutSTyhmcoC9gMeta || {},
    alias: aboutSTyhmcoC9gMeta?.alias || [],
    redirect: aboutSTyhmcoC9gMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountacKeTLyi0XMeta?.path ?? "/account",
    children: [
  {
    name: billingFSXmdKzuUGMeta?.name ?? "account-billing",
    path: billingFSXmdKzuUGMeta?.path ?? "billing",
    meta: billingFSXmdKzuUGMeta || {},
    alias: billingFSXmdKzuUGMeta?.alias || [],
    redirect: billingFSXmdKzuUGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexmJKQzH1GplMeta?.name ?? "account",
    path: indexmJKQzH1GplMeta?.path ?? "",
    meta: indexmJKQzH1GplMeta || {},
    alias: indexmJKQzH1GplMeta?.alias || [],
    redirect: indexmJKQzH1GplMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profilebWVjLByjxvMeta?.name ?? "account-profile",
    path: profilebWVjLByjxvMeta?.path ?? "profile",
    meta: profilebWVjLByjxvMeta || {},
    alias: profilebWVjLByjxvMeta?.alias || [],
    redirect: profilebWVjLByjxvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accountacKeTLyi0XMeta?.name ?? undefined,
    meta: accountacKeTLyi0XMeta || {},
    alias: accountacKeTLyi0XMeta?.alias || [],
    redirect: accountacKeTLyi0XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizebC0zWCw3AOMeta?.name ?? "authorize",
    path: authorizebC0zWCw3AOMeta?.path ?? "/authorize",
    meta: authorizebC0zWCw3AOMeta || {},
    alias: authorizebC0zWCw3AOMeta?.alias || [],
    redirect: authorizebC0zWCw3AOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: chatDu1eOtaRXSMeta?.name ?? "chat",
    path: chatDu1eOtaRXSMeta?.path ?? "/chat",
    meta: chatDu1eOtaRXSMeta || {},
    alias: chatDu1eOtaRXSMeta?.alias || [],
    redirect: chatDu1eOtaRXSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: contactujEufyMBCwMeta?.name ?? "contact",
    path: contactujEufyMBCwMeta?.path ?? "/contact",
    meta: contactujEufyMBCwMeta || {},
    alias: contactujEufyMBCwMeta?.alias || [],
    redirect: contactujEufyMBCwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index6JVFuo1GsQMeta?.name ?? "index",
    path: index6JVFuo1GsQMeta?.path ?? "/",
    meta: index6JVFuo1GsQMeta || {},
    alias: index6JVFuo1GsQMeta?.alias || [],
    redirect: index6JVFuo1GsQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testfBozpUHxUBMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testfBozpUHxUBMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testfBozpUHxUBMeta || {},
    alias: internet_45speed_45testfBozpUHxUBMeta?.alias || [],
    redirect: internet_45speed_45testfBozpUHxUBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: logine2fIx7vbE7Meta?.name ?? "login",
    path: logine2fIx7vbE7Meta?.path ?? "/login",
    meta: logine2fIx7vbE7Meta || {},
    alias: logine2fIx7vbE7Meta?.alias || [],
    redirect: logine2fIx7vbE7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutZmx9vn8KrPMeta?.name ?? "logout",
    path: logoutZmx9vn8KrPMeta?.path ?? "/logout",
    meta: logoutZmx9vn8KrPMeta || {},
    alias: logoutZmx9vn8KrPMeta?.alias || [],
    redirect: logoutZmx9vn8KrPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: our_45planss2WAaFjQBfMeta?.name ?? "our-plans",
    path: our_45planss2WAaFjQBfMeta?.path ?? "/our-plans",
    meta: our_45planss2WAaFjQBfMeta || {},
    alias: our_45planss2WAaFjQBfMeta?.alias || [],
    redirect: our_45planss2WAaFjQBfMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plans0iG8FIexSuMeta?.name ?? "plans",
    path: plans0iG8FIexSuMeta?.path ?? "/plans",
    meta: plans0iG8FIexSuMeta || {},
    alias: plans0iG8FIexSuMeta?.alias || [],
    redirect: plans0iG8FIexSuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacyjOLxfmz8IdMeta?.name ?? "privacy",
    path: privacyjOLxfmz8IdMeta?.path ?? "/privacy",
    meta: privacyjOLxfmz8IdMeta || {},
    alias: privacyjOLxfmz8IdMeta?.alias || [],
    redirect: privacyjOLxfmz8IdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsXqgAhBumHFMeta?.name ?? "promotions",
    path: promotionsXqgAhBumHFMeta?.path ?? "/promotions",
    meta: promotionsXqgAhBumHFMeta || {},
    alias: promotionsXqgAhBumHFMeta?.alias || [],
    redirect: promotionsXqgAhBumHFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    path: registermfHdMOhxJ0Meta?.path ?? "/register",
    children: [
  {
    name: _1BzEFqqG21EMeta?.name ?? "register-1",
    path: _1BzEFqqG21EMeta?.path ?? "1",
    meta: _1BzEFqqG21EMeta || {},
    alias: _1BzEFqqG21EMeta?.alias || [],
    redirect: _1BzEFqqG21EMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2V0LzB7wxTEMeta?.name ?? "register-2",
    path: _2V0LzB7wxTEMeta?.path ?? "2",
    meta: _2V0LzB7wxTEMeta || {},
    alias: _2V0LzB7wxTEMeta?.alias || [],
    redirect: _2V0LzB7wxTEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3lpHF0scz9CMeta?.name ?? "register-3",
    path: _3lpHF0scz9CMeta?.path ?? "3",
    meta: _3lpHF0scz9CMeta || {},
    alias: _3lpHF0scz9CMeta?.alias || [],
    redirect: _3lpHF0scz9CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _43rVTxasymmMeta?.name ?? "register-4",
    path: _43rVTxasymmMeta?.path ?? "4",
    meta: _43rVTxasymmMeta || {},
    alias: _43rVTxasymmMeta?.alias || [],
    redirect: _43rVTxasymmMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _5rJKLqsZdskMeta?.name ?? "register-5",
    path: _5rJKLqsZdskMeta?.path ?? "5",
    meta: _5rJKLqsZdskMeta || {},
    alias: _5rJKLqsZdskMeta?.alias || [],
    redirect: _5rJKLqsZdskMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: _6loJAAl0MNgMeta?.name ?? "register-6",
    path: _6loJAAl0MNgMeta?.path ?? "6",
    meta: _6loJAAl0MNgMeta || {},
    alias: _6loJAAl0MNgMeta?.alias || [],
    redirect: _6loJAAl0MNgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/6.vue").then(m => m.default || m)
  },
  {
    name: indexFYbJe3UNfNMeta?.name ?? "register",
    path: indexFYbJe3UNfNMeta?.path ?? "",
    meta: indexFYbJe3UNfNMeta || {},
    alias: indexFYbJe3UNfNMeta?.alias || [],
    redirect: indexFYbJe3UNfNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successv3whoMdfuJMeta?.name ?? "register-success",
    path: successv3whoMdfuJMeta?.path ?? "success",
    meta: successv3whoMdfuJMeta || {},
    alias: successv3whoMdfuJMeta?.alias || [],
    redirect: successv3whoMdfuJMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registermfHdMOhxJ0Meta?.name ?? undefined,
    meta: registermfHdMOhxJ0Meta || {},
    alias: registermfHdMOhxJ0Meta?.alias || [],
    redirect: registermfHdMOhxJ0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: terms6DdCqxBbrDMeta?.name ?? "terms",
    path: terms6DdCqxBbrDMeta?.path ?? "/terms",
    meta: terms6DdCqxBbrDMeta || {},
    alias: terms6DdCqxBbrDMeta?.alias || [],
    redirect: terms6DdCqxBbrDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1670677190/src/comapss-web/pages/terms.vue").then(m => m.default || m)
  }
]