import { defineNuxtPlugin } from '#imports';
import { createApi } from '~/lib';
import { withTrailingSlash } from 'ufo';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const api = createApi(
    {
      baseURL: `${withTrailingSlash(config.public.apiUrl)}api`,
    },
    {
      'X-Application-Identifier': config.public.appId,
      Accept: 'application/json',
    }
  );

  const affinityApi = createApi(
    {
      baseURL: `${withTrailingSlash(config.public.affinityApiUrl)}api`,
    },
    {
      'X-Application-Identifier': config.public.appId,
      Accept: 'application/json',
    }
  );

  // @ts-ignore
  nuxtApp.$pinia.use(() => ({ $api: api }));

  return {
    provide: { api, affinityApi },
  };
});
